import { Routes } from '@angular/router';

import { AuthGuard, NoAuthGuard } from '@rocketcoms/ops-portal/feature-core';

export const APP_ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth').then((m) => m.AUTH_ROUTES),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'business-accounts',
    loadChildren: () => import('./business-account').then((m) => m.BUSINESS_ACCOUNT_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./campaigns').then((m) => m.CAMPAIGN_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () => import('./user').then((m) => m.USER_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: 'filters',
    loadChildren: () => import('./filter').then((m) => m.FILTER_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings').then((m) => m.SETTINGS_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: `business-accounts`,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'business-accounts',
  },
];
