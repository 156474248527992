import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ENVIRONMENT_INITIALIZER, importProvidersFrom, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, provideRouter } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule, provideStore } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { APP_CONFIG } from '@rocketcoms/app-config';
import {
  AuthEffects,
  AuthGuard,
  CanDeactivateGuard,
  HttpErrorInterceptor,
  JwtInterceptor,
  MessagesEffects,
  ModalsEffects,
  NoAuthGuard,
  PageTitleFactory,
  ROOT_REDUCERS,
  fromAuth,
  metaReducers,
} from '@rocketcoms/ops-portal/feature-core';
import {
  RktFormCheckboxGroupComponent,
  RktFormDatepickerComponent,
  RktFormFieldWrapperComponent,
  RktFormInputCardComponent,
  RktFormInputComponent,
  RktFormInputPasswordComponent,
  RktFormInputPhoneComponent,
  RktFormInputPlaceComponent,
  RktFormInputSecureComponent,
  RktFormRadioGroupComponent,
  RktFormRepeatComponent,
  RktFormRepeatSectionComponent,
  RktFormSelectComponent,
  RktFormSelectCountryComponent,
  RktFormSelectMultiComponent,
  RktFormSelectStateComponent,
  RktFormTextareaComponent,
  RktFormTimepickerComponent,
  RktFormlyFieldGroupComponent,
  RktIconRegistryService,
  arrowDown,
  arrowLeft,
  arrowUp,
  campaign,
  cardNumberValidator,
  check,
  checkboxOff,
  checkboxOn,
  chevronLeft,
  chevronRight,
  company,
  countryCodeValidator,
  doubleArrowLeft,
  doubleArrowRight,
  edit,
  filter,
  filters,
  logout,
  minLengthValidationMessage,
  phoneNumberValidator,
  plus,
  requiredValidationMessage,
  resend,
  stateCodeValidator,
  team,
  times,
  trash,
} from '@rocketcoms/rocket-ui';
import { environment } from '@env-ops';

import { APP_ROUTES } from './app.routes';

interface RktIcon<T> {
  name: T;
  data: string;
}

const admin: RktIcon<'admin'> = {
  name: 'admin',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M12 14v8H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm9 4h1v5h-8v-5h1v-1a3 3 0 0 1 6 0v1zm-2 0v-1a1 1 0 0 0-2 0v1h2z"/></svg>`,
};

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: APP_CONFIG, useValue: environment },
    provideRouter(APP_ROUTES),
    provideStore({}),
    provideEnvironmentNgxMask(),
    importProvidersFrom(
      BrowserModule,
      ToastrModule.forRoot({
        preventDuplicates: true,
      }),
      NgSelectModule,
      FormsModule,
      StoreModule.forRoot(ROOT_REDUCERS, {
        metaReducers,
        runtimeChecks: {
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }),
      EffectsModule.forRoot([MessagesEffects, ModalsEffects]),
      StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
      EffectsModule.forFeature([AuthEffects]),
      StoreRouterConnectingModule.forRoot({ navigationActionTiming: NavigationActionTiming.PostActivation }),
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
      FormlyModule.forRoot({
        types: [
          { name: 'formly-group', component: RktFormlyFieldGroupComponent },
          { name: 'input', component: RktFormInputComponent, wrappers: ['field-wrapper'] },
          { name: 'input-phone', component: RktFormInputPhoneComponent, wrappers: ['field-wrapper'] },
          { name: 'input-place', component: RktFormInputPlaceComponent, wrappers: ['field-wrapper'] },
          { name: 'input-password', component: RktFormInputPasswordComponent, wrappers: ['field-wrapper'] },
          { name: 'input-card', component: RktFormInputCardComponent, wrappers: ['field-wrapper'] },
          { name: 'input-secure', component: RktFormInputSecureComponent, wrappers: ['field-wrapper'] },
          { name: 'number', extends: 'input', defaultOptions: { props: { type: 'number' } } },
          { name: 'select', component: RktFormSelectComponent, wrappers: ['field-wrapper'] },
          { name: 'select-country', component: RktFormSelectCountryComponent, wrappers: ['field-wrapper'] },
          { name: 'select-state', component: RktFormSelectStateComponent, wrappers: ['field-wrapper'] },
          { name: 'select-multi', component: RktFormSelectMultiComponent, wrappers: ['field-wrapper'] },
          { name: 'textarea', component: RktFormTextareaComponent, wrappers: ['field-wrapper'] },
          { name: 'radio-group', component: RktFormRadioGroupComponent, wrappers: ['field-wrapper'] },
          { name: 'checkbox-group', component: RktFormCheckboxGroupComponent, wrappers: ['field-wrapper'] },
          { name: 'repeat', component: RktFormRepeatComponent, wrappers: ['field-wrapper'] },
          { name: 'repeat-section', component: RktFormRepeatSectionComponent, wrappers: ['field-wrapper'] },
          { name: 'datepicker', component: RktFormDatepickerComponent, wrappers: ['field-wrapper'] },
          { name: 'timepicker', component: RktFormTimepickerComponent, wrappers: ['field-wrapper'] },
        ],
        wrappers: [{ name: 'field-wrapper', component: RktFormFieldWrapperComponent }],
        validationMessages: [
          { name: 'required', message: requiredValidationMessage },
          { name: 'minLength', message: minLengthValidationMessage },
        ],
        validators: [
          { name: 'isValidPhoneNumber', validation: phoneNumberValidator },
          { name: 'isValidCountryCode', validation: countryCodeValidator },
          { name: 'isValidStateCode', validation: stateCodeValidator },
          { name: 'isValidCardNumber', validation: cardNumberValidator },
        ],
      }),
    ),
    AuthGuard,
    NoAuthGuard,
    CanDeactivateGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: PageTitleFactory,
      deps: [Router, Title],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () =>
        inject(RktIconRegistryService).registerIcons([
          plus,
          company,
          campaign,
          team,
          filters,
          arrowDown,
          arrowLeft,
          checkboxOff,
          checkboxOn,
          chevronRight,
          filter,
          chevronLeft,
          arrowUp,
          edit,
          trash,
          times,
          logout,
          check,
          doubleArrowLeft,
          doubleArrowRight,
          resend,
          admin,
        ]),
    },
  ],
};
